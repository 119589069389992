.container {
    display: flex;
    height: 100vh;
    background: white;
    padding: 5rem;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.title {
    padding: 1rem 2rem;
}

.formContainer {
    display: flex;
    flex: 0.35;
    flex-direction: column;
}

.formContainer h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.formContainer p {
    font-size: 11px;
}

.right {
    display: flex;
    justify-content: flex-end;
    padding: .5rem 2rem;
    font-size: 13px;
}

.left {
    display: flex;
    justify-content: flex-start;
    padding: .5rem 2rem;
    font-size: 13px;
}