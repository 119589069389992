.container {
    display: flex;
    height: 100vh;
    background: url('../../assets/background.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5rem;
    flex-direction: column;
    justify-content: center;
}

.container p {
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
}

.container h1 {
    font-size: 3rem;
    color: white;
    font-weight: 800;
}