.menubar {
    display: flex;
    padding: .5rem;
}

.menu {
    display: flex;
    padding: 1rem 5rem;
    padding-left: 1rem;
    /* justify-content: center;
    align-items: flex-start; */
    background: none;
    border-bottom: 3px solid whitesmoke;

}

.menu:hover {
    background: teal;
    border-bottom: 3px solid lightseagreen;
    color: white;
    padding: 1rem 3rem;
}

.active {
    background: teal;
    border-bottom: 3px solid lightseagreen;
    color: white;
    padding: 1rem 3rem;
}