.pageTitle {
    display: flex;
    padding: 1rem .5rem;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 1rem;
    font-weight: bold;
}

.name {
    display: flex;
    align-items: center;
}

.search {
    display: flex;
    border-radius: 50px;
    background: white;
    padding: 1rem 2rem;
    align-items: center;
    margin-right: 1rem;
}

.search input {
    outline: none;
    border: none;
    margin-right: 1rem;
}